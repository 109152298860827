export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/affiliates": [3],
		"/coming-soon": [4],
		"/contact": [5],
		"/drawings_version_two/[file_name]": [72],
		"/drawings_version_two/[file_name]/set-images/[set_images_id]": [73],
		"/drawings": [6],
		"/drawings/1923": [7],
		"/drawings/1923/set-image-1": [8],
		"/drawings/1923/set-image-2": [9],
		"/drawings/1923/set-image-3": [10],
		"/drawings/agatha": [11],
		"/drawings/agatha/set-image-1": [12],
		"/drawings/agatha/set-image-2": [13],
		"/drawings/agatha/set-image-3": [14],
		"/drawings/agatha/set-image-4": [15],
		"/drawings/agatha/set-image-5": [16],
		"/drawings/agatha/set-image-6": [17],
		"/drawings/endgame": [18],
		"/drawings/guardians-of-the-galaxy": [19],
		"/drawings/guardians-of-the-galaxy/set-image-1": [20],
		"/drawings/haunted-mansion": [21],
		"/drawings/haunted-mansion/set-image-1": [22],
		"/drawings/haunted-mansion/set-image-2": [23],
		"/drawings/haunted-mansion/set-image-3": [24],
		"/drawings/haunted-mansion/set-image-4": [25],
		"/drawings/hungergamespt1": [26],
		"/drawings/hungergamespt1/set-image-1": [27],
		"/drawings/hungergamespt1/set-image-2": [28],
		"/drawings/hungergamespt1/set-image-3": [29],
		"/drawings/hungergamespt2": [30],
		"/drawings/hungergamespt2/set-image-1": [31],
		"/drawings/hungergamespt2/set-image-2": [32],
		"/drawings/hungergamespt2/set-image-3": [33],
		"/drawings/hungergamespt2/set-image-4": [34],
		"/drawings/hustle": [35],
		"/drawings/infinity-wars": [36],
		"/drawings/insurgent": [37],
		"/drawings/insurgent/set-image-1": [38],
		"/drawings/junglecruise": [39],
		"/drawings/junglecruise/set-image-1": [40],
		"/drawings/junglecruise/set-image-2": [41],
		"/drawings/junglecruise/set-image-3": [42],
		"/drawings/junglecruise/set-image-4": [43],
		"/drawings/killers-of-flower-moon": [44],
		"/drawings/loki": [45],
		"/drawings/rebel-moon": [46],
		"/drawings/rebel-moon/set-image-1": [47],
		"/drawings/rebel-moon/set-image-2": [48],
		"/drawings/rebel-moon/set-image-3": [49],
		"/drawings/rebel-moon/set-image-4": [50],
		"/drawings/rebel-moon/set-image-5": [51],
		"/drawings/rebel-moon/set-image-6": [52],
		"/drawings/she-hulk": [53],
		"/drawings/she-hulk/set-image-1": [54],
		"/drawings/she-hulk/set-image-2": [55],
		"/drawings/she-hulk/set-image-3": [56],
		"/drawings/the-last-airbender": [57],
		"/drawings/the-last-airbender/set-image-1": [58],
		"/drawings/the-last-airbender/set-image-2": [59],
		"/drawings/the-ridiculous-6": [60],
		"/drawings/the-ridiculous-6/set-image-1": [61],
		"/drawings/the-ridiculous-6/set-image-2": [62],
		"/drawings/the-suicide-squad": [63],
		"/drawings/the-suicide-squad/set-image-1": [64],
		"/drawings/the-suicide-squad/set-image-2": [65],
		"/drawings/zombieland-doubletap": [66],
		"/drawings/zombieland-doubletap/set-image-1": [67],
		"/drawings/zombieland-doubletap/set-image-2": [68],
		"/drawings/zombieland-doubletap/set-image-3": [69],
		"/drawings/zombieland-doubletap/set-image-4": [70],
		"/drawings/zombieland-doubletap/set-image-5": [71],
		"/preliminary-sketches": [74],
		"/preliminary-sketches/agatha": [75],
		"/preliminary-sketches/haunted-mansion": [76],
		"/preliminary-sketches/junglecruise": [77],
		"/preliminary-sketches/killers-of-flower-moon": [78],
		"/preliminary-sketches/rebel-moon": [79],
		"/preliminary-sketches/she-hulk": [80],
		"/preliminary-sketches/the-suicide-squad": [81],
		"/recognitions": [82],
		"/resume": [83],
		"/set-photos": [84]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';